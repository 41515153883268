import React, { PropsWithChildren } from 'react';
import { LinkVariant, TypographyVariant } from '@components/themes';
import { styled } from '@styledComponents';
import { LinkWrapper } from './CTALink.styles';
import { ChevronRightIcon } from '@images/ChevronRight';
import { getUrlWithTrailingSlash, isExternalUrl } from '@utils/urlHelpers.util';
import { useGatsbyLinkProps } from '@hooks/useGatsbyLinkProps';

const { segment } = require('../../../static/js/joy');

interface AnchorBaseProps {
  forwardedAs?: 'a';
  href: string;
  target?: string;
  to?: never;
}

interface GatsbyLinkBaseProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedAs: React.ComponentType<any>;
  href?: never;
  target?: never;
  to: string;
}

interface CTAAbstractLinkProps {
  id: string;
  className?: string;
  typography?: TypographyVariant | TypographyVariant[];
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  hasChevron?: boolean;
  telemetryLabel?: string;
}

interface CTADefaultLinkProps extends CTAAbstractLinkProps {
  variant?: LinkVariant;
  size?: 'default' | 'large';
}

interface CTAFooterLinkProps extends CTAAbstractLinkProps {
  variant: 'footer';
  size?: 'default';
}

export type GatsbyLinkProps = GatsbyLinkBaseProps & (CTAFooterLinkProps | CTADefaultLinkProps);

export type CTALinkProps = AnchorBaseProps & (CTAFooterLinkProps | CTADefaultLinkProps);

type BasAllCTALinkProps = CTALinkProps | GatsbyLinkProps;

export type AllCTALinkProps = BasAllCTALinkProps & { hasSpan?: boolean };

const CTALinkBase = ({
  hasSpan = true,
  hasChevron,
  children,
  as,
  forwardedAs,
  target,
  className,
  onClick,
  telemetryLabel,
  ...restProps
}: PropsWithChildren<AllCTALinkProps & { as?: AllCTALinkProps['forwardedAs'] }>) => {
  let rel;
  if (as === 'a') {
    if (isExternalUrl(restProps.href)) {
      target = target || '_blank';
      if (target === '_blank') {
        rel = 'noopener noreferrer';
      }
    }
  }
  if (restProps.to) {
    restProps.to = getUrlWithTrailingSlash(restProps.to);
  }
  const handleOnClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
    segment.redirectWithTelemetry(event, undefined, telemetryLabel);
  };
  return (
    <LinkWrapper as={as} className={className} rel={rel} target={target} {...restProps} onClick={handleOnClick}>
      {hasSpan ? <span>{children}</span> : children}
      {hasChevron ? <ChevronRightIcon /> : null}
    </LinkWrapper>
  );
};

const withGatsbyLink = (Component: React.FunctionComponent<PropsWithChildren<AllCTALinkProps>>) => (
  props: PropsWithChildren<AllCTALinkProps & { as?: never }>
) => {
  const [gatsbyLinkProps] = useGatsbyLinkProps(props);
  return <Component {...gatsbyLinkProps} />;
};

const CTALink = styled(withGatsbyLink(CTALinkBase))<AllCTALinkProps & { as?: never }>``;

CTALink.defaultProps = {
  variant: 'primary',
  forwardedAs: 'a',
  typography: 'featureHed17',
};

export { CTALink };
