import { ABExperimenKey } from '@utils/experiments/experiments';
import { ConfigByStorefront, ValuePropCardsData, HeaderMainMenus, FooterMainMenus } from './storefront.types';
import { HeaderMenuID } from 'src/layout/components/Header/Header.types';

export const VALUE_PROP_CARDS_BY_STOREFRONT: ConfigByStorefront<ValuePropCardsData> = {
  wedding: [
    {
      id: '1',
      title: 'Free Shipping $45+',
      desktopImageUrl: 'value-props/wedding/TopBarDrawerFreeShippingDesktop_v3.png',
      mobileImageUrl: 'value-props/wedding/TopBarDrawerFreeShippingMobile_v3.png',
      link: 'https://withjoy.com/shipping-policy',
      isExternal: true,
    },
    {
      id: '2',
      title: 'Registry Completion Discount',
      desktopImageUrl: 'value-props/wedding/TopBarDrawerPostWeddingDiscountDesktopv2.png',
      mobileImageUrl: 'value-props/wedding/TopBarDrawerPostWeddingDiscountMobile.png',
      link: 'https://help.withjoy.com/knowledge-base/20-percent-post-wedding-discount',
      isExternal: true,
    },
    {
      id: '3',
      title: 'Price Match Promise',
      desktopImageUrl: 'value-props/wedding/TopBarDrawerPriceMatchDesktop.png',
      mobileImageUrl: 'value-props/wedding/TopBarDrawerPriceMatchMobile.png',
      link: 'https://help.withjoy.com/knowledge-base/price-matching',
      isExternal: true,
    },
    {
      id: '4',
      title: 'Hassle Free Returns',
      desktopImageUrl: 'value-props/wedding/TopBarDrawerEasyReturnsDesktop.png',
      mobileImageUrl: 'value-props/wedding/TopBarDrawerEasyReturnsMobile.png',
      link: 'https://withjoy.com/return-policy',
      isExternal: true,
    },
  ],
  baby: [
    {
      id: '1',
      title: 'Free Shipping',
      desktopImageUrl: 'value-props/baby/Top-Bar-Cards-Free-Shipping-Desktop.png',
      mobileImageUrl: 'value-props/baby/Top-Bar-Cards-Free-Shipping-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/free-shipping',
      isExternal: true,
    },
    {
      id: '2',
      title: '20% Completion Discount',
      desktopImageUrl: 'value-props/baby/Top-Bar-Cards-Completion-Discount-Desktop.png',
      mobileImageUrl: 'value-props/baby/Top-Bar-Cards-Completion-Discount-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/20-post-baby-registry-discount',
      isExternal: true,
    },
    {
      id: '3',
      title: 'Price Match Promise',
      desktopImageUrl: 'value-props/baby/Top-Bar-Cards-Price-Match-Desktop.png',
      mobileImageUrl: 'value-props/baby/Top-Bar-Cards-Price-Match-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/baby-registry-price-matching',
      isExternal: true,
    },
    {
      id: '4',
      title: 'Easy Returns',
      desktopImageUrl: 'value-props/baby/Top-Bar-Cards-Easy-Returns-Desktop.png',
      mobileImageUrl: 'value-props/baby/Top-Bar-Cards-Easy-Returns-Mobile.png',
      link: 'https://help.withjoy.com/baby-registry/easy-returns',
      isExternal: true,
    },
  ],
};

export const MAIN_MENU_OPTIONS: ConfigByStorefront<HeaderMainMenus> = {
  wedding: [
    {
      id: 'weddingMenuPlanInvite',
      title: 'Plan & Invite',
      menuId: HeaderMenuID.planYourWedding,
      subMenus: [
        {
          id: 'features-wedding-website',
          to: '/wedding-website',
          title: 'Wedding Website',
        },
        {
          id: 'features-guest-list',
          to: '/guest-list',
          title: 'Guest List',
        },
        {
          id: 'features-save-the-date',
          to: '/save-the-date',
          title: 'Save the Dates',
        },
        {
          id: 'features-wedding-invitations',
          to: '/wedding-invitations',
          title: 'Invitations',
        },
        {
          id: 'contact-collector',
          href: 'https://withjoy.com/blog/how-to-easily-collect-addresses-for-wedding-invitations/',
          target: '_blank',
          title: 'Contact Collector',
        },
        {
          id: 'features-smart-rsvp',
          to: '/online-rsvp',
          title: 'Smart RSVP',
        },
        {
          id: 'features-hotel-room-blocks',
          to: '/hotel-room-blocks',
          title: 'Hotel Room Blocks',
        },
        {
          id: 'features-app',
          to: '/app',
          title: 'Mobile App',
        },
      ],
    },
    {
      id: 'weddingMenuRegistry',
      title: 'Registry',
      menuId: HeaderMenuID.registry,
      subMenus: [
        {
          id: 'features-registry',
          to: '/registry',
          title: 'Wedding Registry',
        },
        {
          id: 'features-shop',
          to: '/shop',
          title: 'The Registry Store',
        },
        {
          id: 'features-cash-fund-registry',
          to: '/cash-fund-registry',
          title: 'Zero-Fee Cash Funds',
        },
        {
          id: 'features-honeymoon-registry',
          to: '/honeymoon-registry',
          title: 'Honeymoon Funds',
        },
      ],
    },
    {
      id: 'weddingMenuExpertAdvice',
      title: 'Expert Advice',
      menuId: HeaderMenuID.resources,
      subMenus: [
        {
          id: 'menu2blog',
          href: 'https://withjoy.com/blog/',
          target: '_blank',
          title: 'Wedding Guides & Ideas',
          mobileTitle: 'Wedding Guides',
          blogMenus: [
            {
              id: 'menu2blogWeddingPlanning',
              href: 'https://withjoy.com/blog/category/wedding-planning/',
              target: '_blank',
              title: 'Wedding Planning',
            },
            {
              id: 'menu2blogBudget',
              href: 'https://withjoy.com/blog/category/budget/',
              target: '_blank',
              title: 'Budget',
            },
            {
              id: 'menu2blogTipsTricks',
              href: 'https://withjoy.com/blog/category/tips-and-tricks/',
              target: '_blank',
              title: 'Tips & Tricks',
            },
            {
              id: 'menu2blogVenues',
              href: 'https://withjoy.com/blog/category/venues/',
              target: '_blank',
              title: 'Venues',
            },
            {
              id: 'menu2blogPhotography',
              href: 'https://withjoy.com/blog/category/photography/',
              target: '_blank',
              title: 'Photography',
            },
            {
              id: 'menu2blogFoodDrink',
              href: 'https://withjoy.com/blog/category/food-drink/',
              target: '_blank',
              title: 'Food & Drink',
            },
            {
              id: 'menu2blogWeddingGuests',
              href: 'https://withjoy.com/blog/category/wedding-guests/',
              target: '_blank',
              title: 'Wedding Guests',
            },
            {
              id: 'menu2blogRegistryGiftIdeas',
              href: 'https://withjoy.com/blog/category/registries/',
              target: '_blank',
              title: 'Registry & Gift Ideas',
            },
            {
              id: 'menu2blogRealWeddings',
              href: 'https://withjoy.com/blog/category/real-weddings/',
              target: '_blank',
              title: 'Real Weddings',
            },
            {
              id: 'menu2blogProposalIdeas',
              href: 'https://withjoy.com/blog/category/proposal-ideas/',
              target: '_blank',
              title: 'Proposal Ideas',
            },
            {
              id: 'menu2blogHoneymoons',
              href: 'https://withjoy.com/blog/category/honeymoon/',
              target: '_blank',
              title: 'Honeymoons',
            },
            {
              id: 'menu2blogViewAllCategories',
              href: 'https://withjoy.com/blog/',
              target: '_blank',
              title: 'View All Categories',
            },
          ],
        },
        {
          id: 'menu2faq',
          to: '/faq',
          title: 'FAQs',
        },
        {
          id: 'menu2help',
          href: 'https://help.withjoy.com/',
          title: 'Help & Support',
        },
      ],
    },
  ],
  baby: [
    {
      id: 'babyMenuRegistry',
      title: 'Registry',
      menuId: HeaderMenuID.planYourWedding,
      subMenus: [
        {
          id: 'features-baby-registry',
          to: '/baby-registry',
          title: 'Baby Registry',
          target: '_blank',
        },
        {
          id: 'features-baby-shop',
          to: '/shop/baby',
          title: 'Shop Baby Gifts',
        },
        {
          id: 'features-baby-zero-fee-cash-fund',
          to: '/shop/baby/catalog/baby-cash-funds',
          title: 'Zero-Fee Cash Funds',
        },
        // TODO - Once we have Babymoon Funds will enable it back
        // {
        //   id: 'features-baby-babymoon-cash-funds',
        //   to: '/babymoon-registry',
        //   title: 'Babymoon Funds',
        // },
      ],
    },
    {
      id: 'babyMenuExpertAdvice',
      title: 'Expert Advice',
      menuId: HeaderMenuID.resources,
      subMenus: [
        {
          id: 'menu2babyblog',
          href: 'https://withjoy.com/baby-guides/',
          target: '_blank',
          title: 'Baby & Parenting Guides',
          mobileTitle: 'Baby Guides',
          blogMenus: [
            {
              id: 'menu2babyblogPregnancy',
              href: 'https://withjoy.com/baby-guides/category/pregnancy/',
              target: '_blank',
              title: 'Pregnancy',
            },
            {
              id: 'menu2babyblogPlanning',
              href: 'https://withjoy.com/baby-guides/category/planning/',
              target: '_blank',
              title: 'Planning',
            },
            {
              id: 'menu2babyblogParties',
              href: 'https://withjoy.com/baby-guides/category/parties/',
              target: '_blank',
              title: 'Parties',
            },
            {
              id: 'menu2babyblogParenting',
              href: 'https://withjoy.com/baby-guides/category/parenting/',
              target: '_blank',
              title: 'Parenting',
            },
            {
              id: 'menu2babyblogViewAllCategories',
              href: 'https://withjoy.com/baby-guides/',
              target: '_blank',
              title: 'View All Categories',
            },
          ],
        },
        {
          id: 'menu2babyhelp',
          href: 'https://help.withjoy.com/baby-registry',
          title: 'Help & Support',
        },
      ],
    },
  ],
};

export const FOOTER_MAIN_MENU_OPTIONS: ConfigByStorefront<FooterMainMenus> = {
  wedding: [
    {
      id: 'footer-main-wedding-website',
      to: '/wedding-website',
      title: 'Wedding Website',
    },
    {
      id: 'footer-main-wedding-guest-list',
      to: '/guest-list',
      title: 'Guest List',
    },
    {
      id: 'footer-main-wedding-save-the-date',
      to: '/save-the-date',
      title: 'Save the Dates',
    },
    {
      id: 'footer-main-wedding-invitations',
      to: '/wedding-invitations',
      title: 'Invitations',
    },
    {
      id: 'footer-main-wedding-smart-rsvp',
      to: '/online-rsvp',
      title: 'Smart RSVP',
    },
    {
      id: 'footer-main-wedding-registry',
      to: '/registry',
      title: 'Wedding Registry',
    },
    {
      id: 'footer-main-hotel-room-blocks',
      to: '/hotel-room-blocks',
      title: 'Hotel Room Blocks',
    },
    {
      id: 'footer-main-wedding-app',
      to: '/app',
      title: 'Mobile App',
    },
  ],
  baby: [
    {
      id: 'footer-main-baby-registry',
      to: '/baby-registry',
      title: 'Baby Registry',
      target: '_blank',
    },
    {
      id: 'footer-main-baby-shop',
      to: '/shop/baby',
      title: 'Shop Baby Gifts',
    },
    {
      id: 'footer-main-baby-zero-fee-cash-fund',
      to: '/shop/baby/catalog/baby-cash-funds',
      title: 'Zero-Fee Cash Funds',
    },
  ],
};

export const FOOTER_MENU_OPTIONS: ConfigByStorefront<FooterMainMenus> = {
  wedding: [
    {
      id: 'footer-wedding-design-templates',
      to: '/designs',
      title: 'Design Templates',
    },
    {
      id: 'footer-wedding-ideas-inspiration',
      to: 'https://withjoy.com/blog/',
      target: '_blank',
      title: 'Ideas & Inspiration',
    },
    {
      id: 'footer-wedding-common-questions',
      to: '/faq',
      title: 'Common Questions',
    },
    {
      id: 'footer-wedding-help-support',
      to: 'https://help.withjoy.com/',
      title: 'Help & Support',
    },
    {
      id: 'footer-wedding-pricing',
      to: '/pricing',
      title: 'Pricing',
    },
  ],
  baby: [
    {
      id: 'footer-baby-baby-parenting-guides',
      to: 'https://withjoy.com/baby-guides/',
      target: '_blank',
      title: 'Baby & Parenting Guides',
    },
    {
      id: 'footer-baby-help-support',
      to: 'https://help.withjoy.com/baby-registry',
      title: 'Help & Support',
    },
  ],
};
