import { styled } from '@styledComponents';
import { Text } from '@components/typography';
import { Button } from '@components/Button';
import { CTALink } from '@components/CTALink';
import { variant } from 'styled-system';
import { Flex } from '@components/Flex';
import { JoykitIcon } from '@components/JoykitIcon';
import { TOP_BANNER_HEIGHT } from 'src/layout/Layout.constants';
import { JoykitColor, LinkVariant, Theme } from '@components/themes';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { GetStartedButton } from '@components/GetStartedButton/GetStartedButton';
import { animationTransition, animationTransitionExt } from '@utils/animationTransition';
import { JOY_LOGO_WIDTH_DESKTOP, JOY_LOGO_WIDTH_MOBILE } from 'src/layout/ParticleJoyLogo.styles';
import { Box } from '@components/Box';

export const TOP_BAR_DEFAULT_HEIGHT_DESKTOP = 96;
export const TOP_BAR_COLLAPSED_HEIGHT_DESKTOP = 80;
export const TOP_BAR_DEFAULT_HEIGHT_MOBILE = 56;
export const TOP_BAR_NAV_PADDING_TABLET = 16;
export const TOP_BAR_NAV_PADDING_DESKTOP = 40;

export const StyledNavMenuLinkWrapper = styled(Flex)`
  row-gap: ${themeSpace(6)};
`;

export const StyledNavMenuLink = styled(CTALink)<{ isVisible?: boolean; tabIndex?: number }>`
  width: 100%;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  span {
    font-style: inherit;
    color: ${themeColor('mono1')};
    &:hover,
    &:active {
      color: ${themeColor('mono12')};
    }
    display: flex;
    column-gap: ${themeSpace(3)};
  }
`;

export const StyledNavMenuLgWrapper = styled(Flex)`
  width: 100%;
  padding: ${themeSpace(8)} 0px;
  padding-left: ${JOY_LOGO_WIDTH_MOBILE}px;
  ${themeMediaQuery('sm')} {
    gap: 32px;
    padding-left: ${JOY_LOGO_WIDTH_DESKTOP + TOP_BAR_NAV_PADDING_TABLET - 4}px;
  }
  ${themeMediaQuery('md')} {
    gap: 48px;
    padding-left: ${JOY_LOGO_WIDTH_DESKTOP + TOP_BAR_NAV_PADDING_DESKTOP - 4}px;
  }
  ${StyledNavMenuLink}, ${StyledNavMenuLink} {
    min-width: 240px;
  }
`;

export const StyledNavBlogMenuLink = styled(Flex)`
  row-gap: ${themeSpace(3)};
`;

export const StyledNavSubMenuLgWrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  gap: ${themeSpace(3)};
  ${themeMediaQuery('md')} {
    column-gap: 48px;
  }
`;

export const ToggleNavParent = styled(Text)<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${themeSpace(1)} 0px;
  > span {
    white-space: normal;
    color: ${props => (props.isActive ? themeColor('mono12') : 'inherit')};
  }
  svg {
    margin-left: ${themeSpace(4)};
    flex-shrink: 0;
    transform: ${({ isActive }) => (isActive ? 'rotate(-180deg)' : 'none')};
    color: ${props => (props.isActive ? themeColor('mono12') : 'inherit')};
  }
`;

export const StyledGetStartedButton = styled(GetStartedButton)`
  border-radius: 4px;
  padding: 0;
  width: 120px;
  height: 43px;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 47px;
  /* Mobile breakpoint */
  ${themeMediaQuery('sm')} {
    width: fit-content;
    padding: 0px 24px;
  }
`;

export const topHeaderLinkHeroTransitionClassName = 'top-header-link-hero-transition';

export const NavMenuDesktopTopBar = styled.nav<{ hiddenMenu: boolean; isScrollCollapsed: boolean; desktopBreakPoint: string }>`
  width: 100%;
  transform: translate(0, ${({ hiddenMenu }) => (hiddenMenu ? '-100%' : '0')});
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px ${themeSpace(5)};
  position: relative;
  z-index: 2;
  transition: ${animationTransition('transform')}, ${animationTransitionExt({ property: 'background-color', duration: '250ms' })};
  @media (max-width: ${({ desktopBreakPoint }) => desktopBreakPoint}) {
    display: none;
  }
  ${themeMediaQuery('md')} {
    padding: 0px ${themeSpace(10)};
  }
`;

export const StyledNavMenuDesktopTopBarLeft = styled(Flex)`
  column-gap: ${themeSpace(5)};
  ${themeMediaQuery('md')} {
    column-gap: ${themeSpace(8)};
  }
`;

export const StyledNavMenuDesktopTopBarRight = styled(Flex)`
  column-gap: ${themeSpace(5)};
  ${themeMediaQuery('md')} {
    column-gap: ${themeSpace(8)};
  }
`;

export const NavMenuDesktopDropShell = styled(Flex)`
  position: relative;
  left: calc(50%);
  transform: translateX(calc(-50%));
  width: calc(100%);
  transition: ${animationTransition('bottom')};
  padding: 0px calc(${themeSpace(5)} + 4px);
  ${themeMediaQuery('md')} {
    padding: 0px calc(${themeSpace(10)} + 4px);
  }
`;

export const NavMenuDesktopDropShellWrapper = styled.div<{
  isOpen: boolean;
  isOpening: boolean;
  isScrollCollapsed: boolean;
  desktopBreakPoint: string;
}>`
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: ${({ isScrollCollapsed }) =>
    isScrollCollapsed ? TOP_BAR_COLLAPSED_HEIGHT_DESKTOP + TOP_BANNER_HEIGHT : TOP_BAR_DEFAULT_HEIGHT_DESKTOP + TOP_BANNER_HEIGHT}px;
  transform: ${({ isOpen, isOpening }) => (isOpen && isOpening ? 'translateY(0)' : 'translateY(-100%)')};
  transition: ${animationTransitionExt({ property: 'transform', duration: '500ms', timingFunction: 'ease-in-out' })};
  @media (max-width: ${({ desktopBreakPoint }) => desktopBreakPoint}) {
    display: none;
  }
`;

export const NavMenuMobileTopBar = styled.nav`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  margin-top: ${themeSpace(7)};
  transition: ${animationTransition('height')}, ${animationTransitionExt({ property: 'background-color', duration: '250ms' })};
`;

export const NavMenuMobileHamburgerImgWrapper = styled.span<{ variant?: LinkVariant }>`
  display: inline-block;
  transition: ${animationTransition('color')};
  svg {
    margin-bottom: -3px;
  }
  ${variant({
    scale: 'links',
    variants: {
      primary: {},
    },
  })}
  color: #333333;
`;

export const NavMenuMobileDropShell = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: ${animationTransition('transform', 'height')};
`;

export const NavMenuMobileDropShellContainer = styled(Flex)`
  row-gap: ${themeSpace(9)};
`;

interface HeaderProps {
  theme: Theme;
  isTransparent: boolean;
  isMenuOpen: boolean;
  backgroundColor: JoykitColor;
}

const headerBackground = ({ theme, isTransparent, isMenuOpen, backgroundColor }: HeaderProps): string =>
  isTransparent ? 'rgba(0, 0, 0, 0)' : isMenuOpen ? theme.colors['white'] : theme.colors[backgroundColor || 'mono7'];

const headerMobileBackground = (): string => 'transparent';

export const StyledHeader = styled.header<{
  isTransparent: boolean;
  backgroundColor: JoykitColor;
  isMenuOpen: boolean;
  hiddenMenu: boolean;
  isScrollCollapsed: boolean;
}>`
  position: ${({ isScrollCollapsed }) => (isScrollCollapsed ? 'fixed' : 'absolute')};
  visibility: ${({ hiddenMenu }) => (hiddenMenu ? 'hidden' : 'visible')};
  z-index: 100;
  top: 0;
  min-width: 100%;
  transition: ${animationTransition('all')};
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : `${TOP_BAR_DEFAULT_HEIGHT_MOBILE}px`)};

  ${NavMenuDesktopTopBar} {
    height: ${({ isScrollCollapsed }) => (isScrollCollapsed ? TOP_BAR_COLLAPSED_HEIGHT_DESKTOP : TOP_BAR_DEFAULT_HEIGHT_DESKTOP)}px;
    background-color: ${headerBackground};
    backdrop-filter: ${props => (props.isTransparent || props.isMenuOpen ? 'saturate(100%) blur(0)' : 'saturate(180%) blur(20px)')};

    .${topHeaderLinkHeroTransitionClassName} {
      color: ${({ isTransparent, theme }) => (isTransparent ? 'inherit' : theme.colors['mono1'])};
      &:hover {
        color: ${({ isTransparent }) => (isTransparent ? 'inherit' : themeColor('mono12'))};
      }
      &:active {
        color: ${({ isTransparent }) => (isTransparent ? 'inherit' : themeColor('mono12'))};
      }
    }
  }

  ${NavMenuMobileTopBar} {
    background-color: ${headerMobileBackground};
    padding-bottom: ${themeSpace(1)};
  }
  ${NavMenuDesktopDropShellWrapper} {
    background-color: ${({ theme }) => theme.colors['white']};
    box-shadow: ${({ isMenuOpen }) =>
      !isMenuOpen ? 'none' : '0px 50px 100px -20px rgba(93, 81, 50, 0.05), 0px 30px 60px -30px rgba(0, 0, 0, 0.06)'};
  }
  ${NavMenuMobileDropShell} {
    background-color: ${({ theme }) => theme.colors['white']};
  }

  .nav-menu-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-menu-right a:first-child {
    margin-left: 0;
  }

  .nav-menu-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  ${themeMediaQuery('sm')} {
    height: ${({ isScrollCollapsed }) => (isScrollCollapsed ? TOP_BAR_COLLAPSED_HEIGHT_DESKTOP : TOP_BAR_DEFAULT_HEIGHT_DESKTOP)}px;
    .nav-menu-left,
    .nav-menu-right {
      height: 58px;
    }
  }
`;

const AnnounceBannerWrapper = styled(Box)`
  position: relative;
  width: 100%;
  z-index: 3;
`;

export const DisplayAboveMobile = styled(AnnounceBannerWrapper)<{ desktopBreakPoint: string }>`
  display: block;
  @media (max-width: ${({ desktopBreakPoint }) => desktopBreakPoint}) {
    display: none;
  }
`;

export const DisplayOnMobile = styled(AnnounceBannerWrapper)<{ desktopBreakPoint: string }>`
  display: none;
  @media (max-width: ${({ desktopBreakPoint }) => desktopBreakPoint}) {
    display: block;
  }
`;

export const NavMenuWrapperMobile = styled.div<{ isScrollCollapsed: boolean; desktopBreakPoint: string }>`
  overflow: hidden;
  @media (min-width: ${({ desktopBreakPoint }) => `calc(${desktopBreakPoint} + 1px)`}) {
    display: none;
  }
`;

export const MobileGetStartedButton = styled(GetStartedButton)`
  padding: 0 ${themeSpace(6)};
  width: 100%;
  margin-bottom: ${themeSpace(5)};
`;

export const MobileLoginButton = styled(Button)`
  width: 100%;
`;

export const MobileFindButton = styled(CTALink)`
  margin-top: ${themeSpace(6)};
  justify-content: center;
  width: 100%;
  height: 56px;
`;

export const HeaderSpacingStub = styled.div`
  height: ${TOP_BAR_DEFAULT_HEIGHT_MOBILE}px;
  ${themeMediaQuery('sm')} {
    height: ${TOP_BAR_DEFAULT_HEIGHT_DESKTOP}px;
  }
`;

export const StyledCloseButton = styled(Button)`
  float: right;
  top: ${themeSpace(6)};
  right: ${themeSpace(6)};
  padding: ${themeSpace(3)};
  background: none;
`;

export const StyledCloseIcon = styled(JoykitIcon)`
  width: ${themeSpace(5)};
  height: ${themeSpace(5)};
`;

export const HamburgerButton = styled(Button)<{ isClosed?: boolean }>`
  padding: ${themeSpace(3)};
  outline: unset !important;
  color: unset;
  line-height: 24px;
  height: 40px;
  width: ${({ isClosed }) => (isClosed ? 'auto' : '50px')};
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(32px);
  border-radius: 64px;
  border: none;
  user-select: none;
  touch-callout: none;
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const MobHeaderContent = styled(Flex)`
  width: 100%;
`;

export const MobGetStartedButton = styled(GetStartedButton)`
  border-radius: 100em 100em 100em 100em;
  margin-right: ${themeSpace(5)};
  line-height: normal;
  height: 40px;
  width: fit-content;
  padding: 0px 24px;
  padding-top: calc(${themeSpace(3)} + 1px);
  padding-bottom: ${themeSpace(3)};
  border: none;
  box-shadow: 0px 2px 5px -1px rgba(68, 50, 93, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.3);
`;
