import { ReactComponent as JoyKitIconClose } from '@images/joykit-icons/icon-close.svg';
import { ReactComponent as JoyKitIconCode } from '@images/joykit-icons/code.svg';
import { ReactComponent as JoyKitIconCopy } from '@images/joykit-icons/copy.svg';
import { ReactComponent as JoyKitIconCollegeFriends } from '@images/joykit-icons/college-friends.svg';
import { ReactComponent as JoyKitIconColor } from '@images/joykit-icons/color.svg';
import { ReactComponent as JoyKitIconCurations } from '@images/joykit-icons/curations.svg';
import { ReactComponent as JoyKitIconDesigns } from '@images/joykit-icons/designs.svg';
import { ReactComponent as JoyKitIconDogBone } from '@images/joykit-icons/dog-bone.svg';
import { ReactComponent as JoyKitIconDownChevron } from '@images/joykit-icons/down-chevron.svg';
import { ReactComponent as JoyKitIconEvent } from '@images/joykit-icons/event.svg';
import { ReactComponent as JoyKitIconFont } from '@images/joykit-icons/font.svg';
import { ReactComponent as JoyKitIconSchedule } from '@images/joykit-icons/schedule.svg';
import { ReactComponent as JoyKitIconTravel } from '@images/joykit-icons/travel.svg';
import { ReactComponent as JoyKitIconVegan } from '@images/joykit-icons/vegan.svg';
import { ReactComponent as JoyKitIconAttending } from '@images/joykit-icons/attending.svg';
import { ReactComponent as JoyKitIconCash } from '@images/joykit-icons/cash.svg';
import { ReactComponent as JoyKitIconGift } from '@images/joykit-icons/gift.svg';
import { ReactComponent as JoyKitIconHairMakeup } from '@images/joykit-icons/hair-makeup.svg';
import { ReactComponent as JoyKitIconHamburger } from '@images/joykit-icons/icon-hamburger.svg';
import { ReactComponent as JoyKitIconHeart } from '@images/joykit-icons/heart.svg';
import { ReactComponent as JoyKitIconHotel } from '@images/joykit-icons/hotel.svg';
import { ReactComponent as JoyKitIconOfficiant } from '@images/joykit-icons/officiant.svg';
import { ReactComponent as JoyKitIconNotAttending } from '@images/joykit-icons/not-attending.svg';
import { ReactComponent as JoyKitIconNote } from '@images/joykit-icons/note.svg';
import { ReactComponent as JoyKitIconPending } from '@images/joykit-icons/pending.svg';
import { ReactComponent as JoyKitIconPlane } from '@images/joykit-icons/plane.svg';
import { ReactComponent as JoyKitIconPlay } from '@images/joykit-icons/play.svg';
import { ReactComponent as JoyKitIconPlayCircle } from '@images/joykit-icons/play-circle.svg';
import { ReactComponent as JoyKitIconLink } from '@images/joykit-icons/link_icon.svg';
import { ReactComponent as JoyKitIconShare } from '@images/joykit-icons/share.svg';
import { ReactComponent as JoyKitIconAddToCalendar } from '@images/joykit-icons/add-to-calendar.svg';
import { ReactComponent as JoyKitIconMultipleChoice } from '@images/joykit-icons/multiple-choice.svg';
import { ReactComponent as JoyKitIconAttendance } from '@images/joykit-icons/attendance.svg';
import { ReactComponent as JoyKitIconShortAnswer } from '@images/joykit-icons/short-answer.svg';
import { ReactComponent as JoyKitIconQAndA } from '@images/joykit-icons/question-and-answer.svg';
import { ReactComponent as JoyKitWeddingPartyIcon } from '@images/joykit-icons/wedding-party.svg';
import { ReactComponent as JoyKitStoryIcon } from '@images/joykit-icons/story.svg';
import { ReactComponent as JoyKitRegistryIcon } from '@images/joykit-icons/registry.svg';
import { ReactComponent as JoyKitRegistryOutline } from '@images/joykit-icons/registry-3.svg';
import { ReactComponent as JoyKitInstagramIcon } from '@images/joykit-icons/Instagram.svg';
import { ReactComponent as JoyKitIconUpload } from '@images/joykit-icons/icon1.svg';
import { ReactComponent as JoyKitIconSlideShow } from '@images/joykit-icons/icon2.svg';
import { ReactComponent as JoyKitIconRemember } from '@images/joykit-icons/icon3.svg';
import { ReactComponent as JoyKitIconAnnounce } from '@images/joykit-icons/icon_announce.svg';
import { ReactComponent as JoyKitIconAttendees } from '@images/joykit-icons/icon_attendees.svg';
import { ReactComponent as JoyKitIconPhotos } from '@images/joykit-icons/icon_photos.svg';
import { ReactComponent as JoyKitIconTaxi } from '@images/joykit-icons/icon-taxi.svg';
import { ReactComponent as JoyKitIconTranslator } from '@images/joykit-icons/icon_translator.svg';
import { ReactComponent as JoyKitIconAllergies } from '@images/joykit-icons/icon_allergies.svg';
import { ReactComponent as JoyKitIconSongRequest } from '@images/joykit-icons/icon_song-request.svg';
import { ReactComponent as JoyKitDestinationWedding } from '@images/joykit-icons/icon_destination-wedding.svg';
import { ReactComponent as JoyKitIconWellWishes } from '@images/joykit-icons/icon_well-wishes.svg';
import { ReactComponent as JoyKitIconRings } from '@images/joykit-icons/icon_rings.svg';
import { ReactComponent as JoyKitSchedulerIcon } from '@images/joykit-icons/icon-schedule.svg';
import { ReactComponent as JoyKitSearchIcon } from '@images/joykit-icons/icon-search.svg';
import { ReactComponent as JoyKitSearchLgIcon } from '@images/joykit-icons/icon-search-lg.svg';
import { ReactComponent as JoyKitIconHandWave } from '@images/joykit-icons/icon-hand-wave.svg';
import { ReactComponent as JoyKitIconPhotoGallery } from '@images/joykit-icons/icon-photo-gallery.svg';
import { ReactComponent as JoyKitIconFlight } from '@images/joykit-icons/icon-flight.svg';
import { ReactComponent as JoyKitIconSend } from '@images/joykit-icons/icon-send.svg';
import { ReactComponent as JoyKitIconPhotoUpload } from '@images/joykit-icons/icon-photo-upload.svg';
import { ReactComponent as JoyKitIconAnnouncement } from '@images/joykit-icons/icon_announcement.svg';
import { ReactComponent as JoyKitIconWebsite } from '@images/joykit-icons/icon-website.svg';
import { readonlyStrEnum } from '@utils/readonlyStrEnum';
import { ReactComponent as JoyKitRightArrow } from '@images/joykit-icons/router-nav-arrow.svg';
import { ReactComponent as JoyKitIconFacebook } from '@images/joykit-icons/icon_facebook.svg';
import { ReactComponent as JoyKitIconPinterest } from '@images/joykit-icons/icon_pinterest.svg';
import { ReactComponent as JoyKitIconTwitter } from '@images/joykit-icons/icon_twitter.svg';
import { ReactComponent as JoyKitIconInstagramSocial } from '@images/joykit-icons/icon_instagram.svg';
import { ReactComponent as JoyKitIconInvite } from '@images/joykit-icons/invite.svg';
import { ReactComponent as ECardRSVPIcon } from '@images/joykit-icons/ecard-rsvp.svg';
import { ReactComponent as CustomFonts } from '@images/joykit-icons/custom-fonts.svg';
import { ReactComponent as ConnectedGuestList } from '@images/joykit-icons/connected-guest-list.svg';
import { ReactComponent as MatchingPrints } from '@images/joykit-icons/matching-prints.svg';
import { ReactComponent as DownArrow } from '@images/joykit-icons/down-arrow.svg';
import { ReactComponent as JoyKitIconDressCode } from '@images/joykit-icons/dress-code.svg';
import { ReactComponent as JoyKitIconSingle } from '@images/joykit-icons/single.svg';
import { ReactComponent as JoyKitIconSparkle } from '@images/joykit-icons/sparkle.svg';
import { ReactComponent as JoyKitIconMulti } from '@images/joykit-icons/multi.svg';
import { ReactComponent as JoyKitIconPhone } from '@images/joykit-icons/phone.svg';
import { ReactComponent as JoyKitIconFilter } from '@images/joykit-icons/filter.svg';
import { ReactComponent as JoyKitIconForwardChevron } from '@images/joykit-icons/forward-chevron.svg';
import { ReactComponent as JoyKitIconBackChevron } from '@images/joykit-icons/back-chevron.svg';
import { ReactComponent as JoyKitIconWw } from '@images/joykit-icons/ww.svg';
import { ReactComponent as JoyKitIconAssistence } from '@images/joykit-icons/assistence.svg';
import { ReactComponent as JoyKitIconGiftCards } from '@images/joykit-icons/gift-cards.svg';
import { ReactComponent as JoyKitIconDiamond } from '@images/joykit-icons/diamond.svg';
import { ReactComponent as JoyKitIconCheckMark } from '@images/joykit-icons/icon-checkmark.svg';

import { JoykitIconSize } from '@components/themes/createTheme.util';
//Footer Nav Icons Importing
import { ReactComponent as JoyKitIconInstagramGuestList } from '@images/footerNavs/guest_list-2.svg';
import { ReactComponent as JoyKitIconInstagramInvites } from '@images/footerNavs/rsvp-2.svg';
import { ReactComponent as JoyKitIconInstagramRegistry } from '@images/footerNavs/registry-2.svg';
import { ReactComponent as JoyKitIconInstagramSaveTheDates } from '@images/footerNavs/save_the_date-2.svg';
import { ReactComponent as JoyKitIconInstagramWeddingWebsite } from '@images/footerNavs/page_manager-2.svg';

// App hero section icons
import { ReactComponent as JoyKitIconTaxiHumanaLight2 } from '@images/joykit-icons/icon_taxi_humana_light_2.svg';
import { ReactComponent as JoyKitIconHandWaveHumanaLight2 } from '@images/joykit-icons/hand_wave_humana_light_2.svg';
import { ReactComponent as JoyKitIconPhotoGalleryHumanaLight2 } from '@images/joykit-icons/photo_gallery_humana_light2.svg';
import { ReactComponent as JoyKitIconSendHumanaLight2 } from '@images/joykit-icons/send_humana_light_2.svg';
import { ReactComponent as JoyKitIconFlightHumanaLight2 } from '@images/joykit-icons/flight_humana_light_2.svg';
import { ReactComponent as JoyKitIconSchedulerHumanaLight2 } from '@images/joykit-icons/scheduler_humana_light_2.svg';
import { ReactComponent as JoyKitIconPhotoUploadHumanaLight2 } from '@images/joykit-icons/photo_upload_humana_light_2.svg';
import { ReactComponent as JoyKitIconWebsiteHumanaLight2 } from '@images/joykit-icons/website_humana_light_2.svg';
import { ReactComponent as JoyKitIconAnnouncementHumanaLight2 } from '@images/joykit-icons/announcement_humana_light_2.svg';
import { ReactComponent as JoyKitIconAttendeesHumanaLight2 } from '@images/joykit-icons/attendees_humana_light_2.svg';

// registry department section icons
import { ReactComponent as JoyKitIconBar } from '@images/joykit-icons/icon-bar.svg';
import { ReactComponent as JoyKitIconBed } from '@images/joykit-icons/icon-bed.svg';
import { ReactComponent as JoyKitIconCashfunds } from '@images/joykit-icons/icon-cashfunds.svg';
import { ReactComponent as JoyKitIconCharity } from '@images/joykit-icons/icon-charity.svg';
import { ReactComponent as JoyKitIconExperiences } from '@images/joykit-icons/icon-experiences.svg';
import { ReactComponent as JoyKitIconHome } from '@images/joykit-icons/icon-home.svg';
import { ReactComponent as JoyKitIconKitchen } from '@images/joykit-icons/icon-kitchen.svg';
import { ReactComponent as JoyKitIconTravelBag } from '@images/joykit-icons/icon-travel.svg';
import { ReactComponent as JoyKitIconCatalogBgHover } from '@images/joykit-icons/catalog-bg_hover.svg';
import { ReactComponent as JoyKitIconDiscountCode } from '@images/joykit-icons/discount-code.svg';

// career our values section icons
import { ReactComponent as JoyKitIconTalk } from '@images/joykit-icons/icon-talk.svg';
import { ReactComponent as JoyKitIconIterate } from '@images/joykit-icons/icon-iterate.svg';
import { ReactComponent as JoyKitIconExperiment } from '@images/joykit-icons/icon-experiment.svg';
import { ReactComponent as JoyKitIconEmbrace } from '@images/joykit-icons/icon-embrace.svg';
import { ReactComponent as JoyKitIconChallenge } from '@images/joykit-icons/icon-challenge.svg';

// Value props icons
import { ReactComponent as JoyKitIconFreeShipping } from '@images/joykit-icons/icon-shipping.svg';
import { ReactComponent as JoyKitIconNoFees } from '@images/joykit-icons/icon-no-fees.svg';
import { ReactComponent as JoyKitIconSyncItem } from '@images/joykit-icons/icon-sync-item.svg';
import { ReactComponent as JoyKitIconTag } from '@images/joykit-icons/icon-tag.svg';
import { ReactComponent as JoyKitIconEasyReturns } from '@images/joykit-icons/icon-easy-returns.svg';

// please put in alphabetical order for easier perception

const iconVariants = [
  'addToCalendar',
  'allergies',
  'announce',
  'assistence',
  'attendance',
  'attendees',
  'attending',
  'bar',
  'bed',
  'cash',
  'cashfunds',
  'catalogBgHover',
  'challenge',
  'charity',
  'checkmark',
  'close',
  'code',
  'copy',
  'collegeFriends',
  'color',
  'curations',
  'designs',
  'destinationWedding',
  'diamond',
  'discountCode',
  'dogBone',
  'downChevron',
  'easyReturns',
  'embrace',
  'experiences',
  'experiment',
  'event',
  'freeShipping',
  'font',
  'gift',
  'giftCards',
  'hairMakeup',
  'hamburger',
  'heart',
  'hotel',
  'home',
  'instagram',
  'inviteRZ',
  'iterate',
  'kitchen',
  'link',
  'multipleChoice',
  'noFees',
  'notAttending',
  'note',
  'officiant',
  'pending',
  'photos',
  'plane',
  'play',
  'playCircle',
  'questionAndAnswer',
  'registry',
  'registryOutline',
  'rings',
  'remember',
  'schedule',
  'share',
  'shortAnswer',
  'slideShow',
  'songRequest',
  'story',
  'syncItem',
  'tag',
  'taxi',
  'talk',
  'translator',
  'travel',
  'travelbag',
  'upload',
  'vegan',
  'weddingParty',
  'wellWishes',
  'scheduler',
  'search',
  'searchLg',
  'handWave',
  'photoGallery',
  'flight',
  'send',
  'photoUpload',
  'announcement',
  'website',
  'rightArrow',
  'facebook',
  'pinterest',
  'twitter',
  'instagramSocial',
  'connectedGuestList',
  'matchingPrints',
  'eCardRSVP',
  'customFonts',
  'downArrow',
  'dressCode',
  //Footer Nav Icons variants
  'guestList',
  'invite',
  'registryIcon',
  'saveTheDate',
  'weddingWebsite',
  // App hero icons
  'taxiHumanaLight2',
  'handWaveHumanaLight2',
  'photoGalleryHumanaLight2',
  'sendHumanaLight2',
  'flightHumanaLight2',
  'schedulerHumanaLight2',
  'photoUploadHumanaLight2',
  'websiteHumanaLight2',
  'announcementHumanaLight2',
  'attendeesHumanaLight2',
  'single',
  'sparkle',
  'multi',
  'phone',
  'filter',
  'forwardChevron',
  'backChevron',
  'ww',
] as const;
const IconVariantsEnum = readonlyStrEnum(iconVariants);
export type JoykitIconVariant = keyof typeof IconVariantsEnum;

interface IconDefinition
  extends Readonly<{
    a11yLabel: string;
    defaultSize: JoykitIconSize;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    svg: React.ComponentType<any>;
  }> {}

const JoykitIconsDictionary: Readonly<{ [key in JoykitIconVariant]: IconDefinition }> = {
  addToCalendar: { a11yLabel: 'Add to Calendar', svg: JoyKitIconAddToCalendar, defaultSize: 48 },
  allergies: { a11yLabel: 'Allergies', svg: JoyKitIconAllergies, defaultSize: 48 },
  announce: { a11yLabel: 'Announce', svg: JoyKitIconAnnounce, defaultSize: 48 },
  assistence: { a11yLabel: 'Assistence', svg: JoyKitIconAssistence, defaultSize: 48 },
  attendance: { a11yLabel: 'Attendance', svg: JoyKitIconAttendance, defaultSize: 48 },
  attendees: { a11yLabel: 'Attendees', svg: JoyKitIconAttendees, defaultSize: 48 },
  attending: { a11yLabel: 'Attending', svg: JoyKitIconAttending, defaultSize: 24 },
  cash: { a11yLabel: 'Cash', svg: JoyKitIconCash, defaultSize: 48 },
  checkmark: { a11yLabel: 'Checkmark', svg: JoyKitIconCheckMark, defaultSize: 24 },
  close: { a11yLabel: 'Icon Close', svg: JoyKitIconClose, defaultSize: 48 },
  code: { a11yLabel: 'Code', svg: JoyKitIconCode, defaultSize: 48 },
  copy: { a11yLabel: 'Copy', svg: JoyKitIconCopy, defaultSize: 48 },
  collegeFriends: { a11yLabel: 'College Friends', svg: JoyKitIconCollegeFriends, defaultSize: 48 },
  color: { a11yLabel: 'Color', svg: JoyKitIconColor, defaultSize: 48 },
  curations: { a11yLabel: 'Curations', svg: JoyKitIconCurations, defaultSize: 48 },
  designs: { a11yLabel: 'Designs', svg: JoyKitIconDesigns, defaultSize: 48 },
  destinationWedding: { a11yLabel: 'Destination Wedding', svg: JoyKitDestinationWedding, defaultSize: 48 },
  dogBone: { a11yLabel: 'Dog Bone', svg: JoyKitIconDogBone, defaultSize: 48 },
  downChevron: { a11yLabel: 'Down Chevron', svg: JoyKitIconDownChevron, defaultSize: 48 },
  diamond: { a11yLabel: 'Diamond', svg: JoyKitIconDiamond, defaultSize: 48 },
  discountCode: { a11yLabel: 'Discount Code', svg: JoyKitIconDiscountCode, defaultSize: 48 },
  event: { a11yLabel: 'Event', svg: JoyKitIconEvent, defaultSize: 48 },
  font: { a11yLabel: 'Font', svg: JoyKitIconFont, defaultSize: 48 },
  gift: { a11yLabel: 'Gift', svg: JoyKitIconGift, defaultSize: 48 },
  giftCards: { a11yLabel: 'GiftCards', svg: JoyKitIconGiftCards, defaultSize: 48 },
  hairMakeup: { a11yLabel: 'Hair & Makeup', svg: JoyKitIconHairMakeup, defaultSize: 48 },
  hamburger: { a11yLabel: 'Icon Hamburger', svg: JoyKitIconHamburger, defaultSize: 48 },
  heart: { a11yLabel: 'Heart', svg: JoyKitIconHeart, defaultSize: 48 },
  hotel: { a11yLabel: 'Hotel', svg: JoyKitIconHotel, defaultSize: 48 },
  link: { a11yLabel: 'Link', svg: JoyKitIconLink, defaultSize: 24 },
  multipleChoice: { a11yLabel: 'Multiple Choice', svg: JoyKitIconMultipleChoice, defaultSize: 48 },
  pending: { a11yLabel: 'Pending', svg: JoyKitIconPending, defaultSize: 24 },
  notAttending: { a11yLabel: 'Not Attending', svg: JoyKitIconNotAttending, defaultSize: 24 },
  note: { a11yLabel: 'Note', svg: JoyKitIconNote, defaultSize: 48 },
  officiant: { a11yLabel: 'Officiant', svg: JoyKitIconOfficiant, defaultSize: 48 },
  schedule: { a11yLabel: 'Schedule', svg: JoyKitIconSchedule, defaultSize: 48 },
  plane: { a11yLabel: 'Plane', svg: JoyKitIconPlane, defaultSize: 48 },
  play: { a11yLabel: 'Play', svg: JoyKitIconPlay, defaultSize: 48 },
  playCircle: { a11yLabel: 'Play Circle', svg: JoyKitIconPlayCircle, defaultSize: 48 },
  travel: { a11yLabel: 'Travel', svg: JoyKitIconTravel, defaultSize: 48 },
  vegan: { a11yLabel: 'Vegan', svg: JoyKitIconVegan, defaultSize: 48 },
  share: { a11yLabel: 'Share', svg: JoyKitIconShare, defaultSize: 24 },
  questionAndAnswer: { a11yLabel: 'Question & Answer', svg: JoyKitIconQAndA, defaultSize: 48 },
  weddingParty: { a11yLabel: 'Wedding Party', svg: JoyKitWeddingPartyIcon, defaultSize: 48 },
  story: { a11yLabel: 'Story', svg: JoyKitStoryIcon, defaultSize: 48 },
  registry: { a11yLabel: 'Registry', svg: JoyKitRegistryIcon, defaultSize: 48 },
  registryOutline: { a11yLabel: 'Registry', svg: JoyKitRegistryOutline, defaultSize: 48 },
  instagram: { a11yLabel: 'Instagram', svg: JoyKitInstagramIcon, defaultSize: 48 },
  inviteRZ: { a11yLabel: 'Invite', svg: JoyKitIconInvite, defaultSize: 48 },
  shortAnswer: { a11yLabel: 'Short Anwser', svg: JoyKitIconShortAnswer, defaultSize: 48 },
  songRequest: { a11yLabel: 'Song Request', svg: JoyKitIconSongRequest, defaultSize: 48 },
  upload: { a11yLabel: 'Upload', svg: JoyKitIconUpload, defaultSize: 48 },
  slideShow: { a11yLabel: 'SlideShow', svg: JoyKitIconSlideShow, defaultSize: 48 },
  remember: { a11yLabel: 'Remember', svg: JoyKitIconRemember, defaultSize: 48 },
  photos: { a11yLabel: 'Photos', svg: JoyKitIconPhotos, defaultSize: 48 },
  taxi: { a11yLabel: 'Taxi', svg: JoyKitIconTaxi, defaultSize: 48 },
  translator: { a11yLabel: 'Translator', svg: JoyKitIconTranslator, defaultSize: 48 },
  wellWishes: { a11yLabel: 'Well Wishes', svg: JoyKitIconWellWishes, defaultSize: 48 },
  rings: { a11yLabel: 'Rings', svg: JoyKitIconRings, defaultSize: 48 },
  scheduler: { a11yLabel: 'Scheduler', svg: JoyKitSchedulerIcon, defaultSize: 48 },
  search: { a11yLabel: 'Search', svg: JoyKitSearchIcon, defaultSize: 48 },
  searchLg: { a11yLabel: 'Search Lg', svg: JoyKitSearchLgIcon, defaultSize: 48 },
  handWave: { a11yLabel: 'Hand Wave', svg: JoyKitIconHandWave, defaultSize: 48 },
  photoGallery: { a11yLabel: 'Photo Galley', svg: JoyKitIconPhotoGallery, defaultSize: 48 },
  flight: { a11yLabel: 'Flight', svg: JoyKitIconFlight, defaultSize: 48 },
  send: { a11yLabel: 'Send', svg: JoyKitIconSend, defaultSize: 48 },
  photoUpload: { a11yLabel: 'Photo upload', svg: JoyKitIconPhotoUpload, defaultSize: 48 },
  announcement: { a11yLabel: 'Announcement', svg: JoyKitIconAnnouncement, defaultSize: 48 },
  website: { a11yLabel: 'Website', svg: JoyKitIconWebsite, defaultSize: 48 },
  rightArrow: { a11yLabel: 'Right Arrow', svg: JoyKitRightArrow, defaultSize: 40 },
  facebook: { a11yLabel: 'Facebook', svg: JoyKitIconFacebook, defaultSize: 40 },
  pinterest: { a11yLabel: 'Pinterest', svg: JoyKitIconPinterest, defaultSize: 40 },
  twitter: { a11yLabel: 'Twitter', svg: JoyKitIconTwitter, defaultSize: 40 },
  instagramSocial: { a11yLabel: 'InstagramSocial', svg: JoyKitIconInstagramSocial, defaultSize: 40 },
  //FooterNav Icons
  guestList: { a11yLabel: 'GuestList', svg: JoyKitIconInstagramGuestList, defaultSize: 24 },
  invite: { a11yLabel: 'Invite', svg: JoyKitIconInstagramInvites, defaultSize: 24 },
  registryIcon: { a11yLabel: 'RegistryIcon', svg: JoyKitIconInstagramRegistry, defaultSize: 24 },
  saveTheDate: { a11yLabel: 'SaveTheDate', svg: JoyKitIconInstagramSaveTheDates, defaultSize: 24 },
  weddingWebsite: { a11yLabel: 'WeddingWebsite', svg: JoyKitIconInstagramWeddingWebsite, defaultSize: 24 },
  // App hero icons
  taxiHumanaLight2: { a11yLabel: 'TaxiHumanaLight2', svg: JoyKitIconTaxiHumanaLight2, defaultSize: 40 },
  handWaveHumanaLight2: { a11yLabel: 'HandWaveHumanaLight2', svg: JoyKitIconHandWaveHumanaLight2, defaultSize: 40 },
  photoGalleryHumanaLight2: { a11yLabel: 'PhotoGalleyHumanaLight2', svg: JoyKitIconPhotoGalleryHumanaLight2, defaultSize: 40 },
  sendHumanaLight2: { a11yLabel: 'SendHumanaLight2', svg: JoyKitIconSendHumanaLight2, defaultSize: 40 },
  flightHumanaLight2: { a11yLabel: 'FlightHumanaLight2', svg: JoyKitIconFlightHumanaLight2, defaultSize: 40 },
  schedulerHumanaLight2: { a11yLabel: 'SchedulerHumanaLight2', svg: JoyKitIconSchedulerHumanaLight2, defaultSize: 40 },
  photoUploadHumanaLight2: { a11yLabel: 'PhotoUploadHumanaLight2', svg: JoyKitIconPhotoUploadHumanaLight2, defaultSize: 40 },
  websiteHumanaLight2: { a11yLabel: 'WebsiteHumanaLight2', svg: JoyKitIconWebsiteHumanaLight2, defaultSize: 40 },
  announcementHumanaLight2: { a11yLabel: 'AnnouncementHumanaLight2', svg: JoyKitIconAnnouncementHumanaLight2, defaultSize: 40 },
  attendeesHumanaLight2: { a11yLabel: 'AttendeesHumanaLight2', svg: JoyKitIconAttendeesHumanaLight2, defaultSize: 40 },
  connectedGuestList: { a11yLabel: 'ConnectedGuestList', svg: ConnectedGuestList, defaultSize: 40 },
  eCardRSVP: { a11yLabel: 'ECardRSVP', svg: ECardRSVPIcon, defaultSize: 40 },
  customFonts: { a11yLabel: 'CustomFonts', svg: CustomFonts, defaultSize: 40 },
  downArrow: { a11yLabel: 'DownArrow', svg: DownArrow, defaultSize: 40 },
  dressCode: { a11yLabel: 'Dress Code', svg: JoyKitIconDressCode, defaultSize: 40 },
  matchingPrints: { a11yLabel: 'MatchingPrints', svg: MatchingPrints, defaultSize: 40 },
  bar: { a11yLabel: 'Bar', svg: JoyKitIconBar, defaultSize: 48 },
  bed: { a11yLabel: 'Bed', svg: JoyKitIconBed, defaultSize: 48 },
  cashfunds: { a11yLabel: 'Cash Funds', svg: JoyKitIconCashfunds, defaultSize: 48 },
  charity: { a11yLabel: 'Charity', svg: JoyKitIconCharity, defaultSize: 48 },
  experiences: { a11yLabel: 'Experiences', svg: JoyKitIconExperiences, defaultSize: 48 },
  home: { a11yLabel: 'Home', svg: JoyKitIconHome, defaultSize: 48 },
  kitchen: { a11yLabel: 'Kitchen', svg: JoyKitIconKitchen, defaultSize: 48 },
  travelbag: { a11yLabel: 'Travel Bag', svg: JoyKitIconTravelBag, defaultSize: 48 },
  catalogBgHover: { a11yLabel: 'Catalog Bg Hover', svg: JoyKitIconCatalogBgHover, defaultSize: 48 },
  single: { a11yLabel: 'Single Page', svg: JoyKitIconSingle, defaultSize: 24 },
  sparkle: { a11yLabel: 'Sparkle', svg: JoyKitIconSparkle, defaultSize: 24 },
  multi: { a11yLabel: 'Multi-Page', svg: JoyKitIconMulti, defaultSize: 24 },
  phone: { a11yLabel: 'Phone', svg: JoyKitIconPhone, defaultSize: 40 },
  filter: { a11yLabel: 'Filter', svg: JoyKitIconFilter, defaultSize: 40 },
  forwardChevron: { a11yLabel: 'Phone', svg: JoyKitIconForwardChevron, defaultSize: 40 },
  backChevron: { a11yLabel: 'Filter', svg: JoyKitIconBackChevron, defaultSize: 40 },
  talk: { a11yLabel: 'Talk', svg: JoyKitIconTalk, defaultSize: 40 },
  iterate: { a11yLabel: 'Iterate', svg: JoyKitIconIterate, defaultSize: 40 },
  experiment: { a11yLabel: 'Experiment', svg: JoyKitIconExperiment, defaultSize: 40 },
  embrace: { a11yLabel: 'Embrace', svg: JoyKitIconEmbrace, defaultSize: 40 },
  challenge: { a11yLabel: 'Challenge', svg: JoyKitIconChallenge, defaultSize: 40 },
  ww: { a11yLabel: 'WW', svg: JoyKitIconWw, defaultSize: 40 },
  // Value props icons
  easyReturns: { a11yLabel: 'Easy Returns', svg: JoyKitIconEasyReturns, defaultSize: 48 },
  freeShipping: { a11yLabel: 'Free Shipping', svg: JoyKitIconFreeShipping, defaultSize: 48 },
  noFees: { a11yLabel: 'No Fees', svg: JoyKitIconNoFees, defaultSize: 48 },
  syncItem: { a11yLabel: 'Sync Item', svg: JoyKitIconSyncItem, defaultSize: 48 },
  tag: { a11yLabel: 'Sync Item', svg: JoyKitIconTag, defaultSize: 48 },
};

export const getIconDefinition = (iconType: JoykitIconVariant) => {
  return JoykitIconsDictionary[iconType];
};
