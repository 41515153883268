import { ButtonVariant } from '@components/Button';
import { JoykitColor, LinkVariant } from '@components/themes';
import { ABExperimenKey } from '@utils/experiments/experiments';

export enum HeaderMenuID {
  planYourWedding = 'planYourWedding',
  registry = 'registry',
  resources = 'resources',
  mobileNavMenu = 'mobileNavMenu',
  findAnEvent = 'findAnEvent',
}

export interface HeaderSubMenu {
  id: string;
  title: string;
  mobileTitle?: string;
  to?: string;
  href?: string;
  target?: string;
  experimentKey?: ABExperimenKey;
  blogMenus?: ReadonlyArray<Omit<HeaderSubMenu, 'blogMenus'>>;
}

export interface HeaderMainMenu {
  id: string;
  title: string;
  menuId: HeaderMenuID;
  subMenus: ReadonlyArray<HeaderSubMenu>;
}

export interface HeaderNavMenuLinkProps {
  idx: number;
  hasBlogs: boolean;
  id: string;
  title: string;
  handleOpenBlogMenu: (idx: number) => void;
  to?: string;
  href?: string;
  target?: string;
  experimentKey?: ABExperimenKey;
  isVisible?: boolean;
  tabIndex?: number;
}

export interface HeaderBlogMenuLinkProps {
  idx: number;
  id: string;
  title: string;
  to?: string;
  href?: string;
  target?: string;
  experimentKey?: ABExperimenKey;
  isVisible?: boolean;
  tabIndex?: number;
}

export interface HeaderMobileNavMenuLinkProps {
  idx: number;
  idxParent: number;
  hasBlogs: boolean;
  id: string;
  title: string;
  handleOpenBlogMenu: (id: number, idx: number) => void;
  to?: string;
  href?: string;
  target?: string;
  experimentKey?: ABExperimenKey;
  isVisible?: boolean;
  tabIndex?: number;
}

export interface HeaderProps {
  isTransparent?: boolean;
  scrollThreshold?: number;
  headerBackgroundColor?: JoykitColor;
  transparentJoyLogoColor?: JoykitColor;
  transparentHeaderLinkVariant?: LinkVariant;
  transparentHeaderButtonVariant?: ButtonVariant;
  page?: MarcomPageName;
  handleGetStarted?: (e: React.MouseEvent) => void;
  handleHamburgerLongPress?: () => void;
}
